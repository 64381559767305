import React, { FC, useState, useEffect, useCallback, useMemo } from "react";
import { User } from "../../models/users/userInterfaces";
import { Note } from "../../models/applications/applicationInterfaces";
import { useDispatch, useSelector } from "react-redux";
import truncate from "truncate";
import {
  addNote,
  deleteNote,
  selectApplicationDetail,
  updateNote,
} from "../../models/applications/applicationDetailSlice";
import { PencilFill, TrashFill } from "react-bootstrap-icons";
import "./notes.scss";
import { useDebouncedCallback } from "use-debounce";
import { formatDate } from "../../utils/helpers";
import { ca } from "date-fns/locale";

interface Props {
  applicant: User;
  notes: Note[];
  activeNote: number;
  setActiveNote: any;
}

export const Notes: FC<Props> = ({ notes, activeNote, setActiveNote }) => {
  const application = useSelector(selectApplicationDetail);
  const appDetails = useMemo(() => application.application, [application]);
  const permissions = useMemo(
    () => application.application?.permissions,
    [application]
  );
  const canEditNotes = useMemo(
    () => !!permissions?.includes("create-application-note"),
    [permissions]
  );

  // If the following text fields are provided, set the text to "" so that the placeholder text displays instead.
  // This check only runs when the note is loaded... not while the user is typing.
  const formatText = (textField: string, text: string) => {
    if (!text) return "";
    if (textField === "title" && text === "Untitled Note") return "";
    if (textField === "body" && text === "Add your note content here.")
      return "";
    return text;
  };
  const dispatch = useDispatch();

  const BODY_MAX_LENGTH = 2000;
  const [title, setTitle] = useState(
    formatText("title", notes[activeNote]?.title || "")
  );
  const [body, setBody] = useState(
    formatText("body", notes[activeNote]?.body || "")
  );

  // const [noteBeingDeleted, setNoteBeingDeleted] = useState<number>();
  const [notesList, setNotesList] = useState(notes);

  // Save the note automatically when the user stops typing.
  const debouncedAutosave = useDebouncedCallback(
    (value: string, type: string) => {
      type === "title"
        ? dispatch(
            updateNote({
              applicationId: appDetails?.id,
              noteId: notes[activeNote].id,
              title: value || "Untitled Note",
            })
          )
        : dispatch(
            updateNote({
              applicationId: appDetails?.id,
              noteId: notes[activeNote].id,
              body: value,
            })
          );
    },
    // Delay in ms
    250
  );
  // On initial load, load the first note after a slight delay.
  useEffect(() => {
    setTimeout(() => {
      loadNote(activeNote);
    }, 200);
  }, []);

  // When new notes are loaded from Redux's state, update local state with the results.
  useEffect(() => {
    setNotesList(notes);
  }, [notes]);

  // When the active note changes, update the viewed note appropriately
  useEffect(() => {
    loadNote(activeNote);
  }, [activeNote]);

  const loadNote = (selectedKey: number, clonedList?: Note[]) => {
    const note = clonedList ? clonedList[selectedKey] : notesList[selectedKey];
    note?.title && setTitle(formatText("title", note.title || ""));
    note?.body && setBody(formatText("body", note.body || ""));
  };

  const newNote = () => {
    // Immediately add note to a temporary clonedNotesList and re-render current list with this modified copy.
    // This makes the transaction appear instantaneous to the user and masks the api call. The cloned list will be
    // replaced with updated list when transaction is completed.
    const clonedNotesList = Array.from(notesList);
    clonedNotesList.unshift({
      title: "Untitled Note",
      body: "Add your note content here.",
    });
    setNotesList(clonedNotesList);
    loadNote(0, clonedNotesList);
    setActiveNote(0);
    appDetails && dispatch(addNote(appDetails.id));
  };

  if (!permissions?.includes("view-application-note")) {
    return <>You do not have permission to view this page.</>;
  }

  return (
    <div className="notes_container">
      {/* LEFT COLUMN - NOTES LIST */}
      <div className="notes_list">
        <div className="container p-0">
          {/* UI MENU - "Add Notes" and Pagination */}
          {canEditNotes && (
            <button className="w-100 btn btn-primary" onClick={newNote}>
              <PencilFill className="mr-2" />
              New Note
            </button>
          )}
        </div>
        {/* Map an individual note list item for each item in data... */}
        {notesList.map((note, key) => {
          return (
            <div
              className={`${
                key === activeNote && "notes_list__item-active"
              } notes_list__item`}
              onClick={() => setActiveNote(key)}
            >
              <div className="notes_list__item_title">
                {/* Note Title Preview */}
                {note.title && truncate(note.title, 22)}
                {/* Delete the Note (Trash Icon) */}
                {canEditNotes && (
                  <TrashFill
                    className="trash-hover"
                    onClick={() => {
                      // Mask the API call with a cloned list. See "newNote" above.
                      const clonedNotesList = Array.from(notesList);
                      clonedNotesList.splice(key, 1);
                      setNotesList(clonedNotesList);
                      loadNote(0, clonedNotesList);
                      // Delete the original note.
                      dispatch(
                        deleteNote({
                          applicationId: appDetails?.id,
                          noteId: note.id,
                        })
                      );
                    }}
                  />
                )}
              </div>
              {/* Note Body Preview */}
              <div className="notes_list__item_body">
                {note.body && truncate(note.body, 58)}
              </div>
            </div>
          );
        })}
      </div>
      {/* RIGHT COLUMN - NOTE VIEWER */}
      <div className="notes_viewer">
        {notesList.length > 0 ? (
          <>
            <input
              className="notes_viewer__title h1"
              value={title}
              placeholder="Untitled Note"
              onChange={(e) => {
                setTitle(e.target.value);
                debouncedAutosave(e.target.value, "title");
              }}
            />
            <div className="notes_viewer__createdAt">
              Created:{" "}
              {notes[activeNote]?.createdAt
                ? formatDate(notes[activeNote].createdAt || "")
                : "Loading..."}
            </div>
            <div className="notes_viewer__updatedAt">
              Last Updated:{" "}
              {notes[activeNote]?.updatedAt
                ? formatDate(notes[activeNote].updatedAt || "")
                : "Loading..."}
            </div>
            {/* Note Content */}
            <div className="d-flex flex-column h-100">
              <textarea
                className="notes_viewer__body"
                value={body}
                placeholder="Add your note content here."
                onChange={(e) => {
                  setBody(e.target.value);
                  debouncedAutosave(e.target.value, "body");
                }}
              />
              <div
                className={`${
                  body.length > BODY_MAX_LENGTH && "text-danger"
                } mt-4 w-100`}
              >
                {body.length} / 2000 characters
              </div>
            </div>
          </>
        ) : (
          <div className="notes_empty">
            {canEditNotes ? (
              <div>
                Click <PencilFill /> "New Note" to add a note
              </div>
            ) : (
              `No notes yet. When your team members add notes, they will appear here.`
            )}
          </div>
        )}
      </div>
    </div>
  );
};
