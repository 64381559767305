import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import {
  ApplicationAPIResponse,
  TasksAPIResponse,
  Task,
  Message,
  ActivityItem,
  ApplicationActivityAPIResponse,
  Questions,
  ApplicationDetail,
  Note,
  NotesAPIResponse,
  DecisionStatus,
  DecisionStatusLog,
  BusinessEvaluationResponse,
  FinancialApplication,
  FinancialApplicationFraudResult,
  FinancialApplicationWatchlistOrder,
  Pdf,
  Screenshot,
} from "./applicationInterfaces";
import api from "../../utils/api";
import { RootState } from "../../app/store";
import { generateImageUrl } from "../../utils/helpers";
import {
  Flow,
  OnboardingApplication,
} from "../onboardingApplication/onboardingApplicationInterfaces";

interface ApplicationDetailState {
  application: ApplicationDetail | undefined;
  activity: ActivityItem[];
  decisionStatusLogs: DecisionStatusLog[];
  tasks: Task[];
  pdfs: Pdf[];
  screenshots: Screenshot[];
  notes: Note[];
  messages: Message[];
  fraudData: any;
  businessGuardData?: BusinessEvaluationResponse;
  fisGkycEvaluations: any;
  decisionStatus?: DecisionStatus;
  availableDecisionStatuses?: DecisionStatus[];
  applicationsData?: FinancialApplication;
  fraudResultsData?: FinancialApplicationFraudResult[];
  onboardingApplicationData?: OnboardingApplication;
  flowData?: Flow;
  watchlistData?: FinancialApplicationWatchlistOrder[];
}

const initialState: ApplicationDetailState = {
  activity: [],
  decisionStatusLogs: [],
  tasks: [],
  pdfs: [],
  screenshots: [],
  application: undefined,
  notes: [],
  messages: [],
  fraudData: [],
  businessGuardData: undefined,
  fisGkycEvaluations: [],
  applicationsData: undefined,
  fraudResultsData: undefined,
  onboardingApplicationData: undefined,
  flowData: undefined,
  watchlistData: undefined,
};

interface ChangeDecisionStatusPayload {
  newDecisionStatusUuid: string;
  denialReason?: string;
}

// -------------- API METHODS CONNECTED TO APPLICATION --------------
// *** GET SUBMISSION DATA FOR APPLICATION DETAILS ***
export const getApplicationDetail = createAsyncThunk<ApplicationDetail, number>(
  "applicationDetail/getApplicationDetail",
  async (id, _thunkApi) => {
    const { data } = await api.get<ApplicationAPIResponse>(
      `/submissions/${id}`
    );
    const result: ApplicationDetail = {
      ...data,
      organization: {
        ...data.organization,
        imgUrl: generateImageUrl(data.organization),
      },
      user: {
        userId: data.user.id,
        firstName: data.user.firstName,
        lastName: data.user.lastName,
        imgUrl: generateImageUrl(data.user),
        email: data.user.email,
        phone: data.user.phone,
      },
      permissions: data.permissions,
    };

    return result;
  }
);

// *** GET ONBOARDING APPLICATION RESPONSES ***
export const getOnboardingApplicationResponses = createAsyncThunk<
  OnboardingApplication,
  number
>(
  "applicationDetail/getOnboardingApplicationResponses",
  async (id, _thunkApi) => {
    const { data } = await api.get<OnboardingApplication>(
      `/submissions/${id}/onboarding-applications`
    );
    // If the flow is already present in the saved application, return the data as is.
    if (data.flow) {
      return data;
    }
    // Otherwise, fetch the flow data as it is presently stored in the DB and return the data with the flow included.
    const { data: flowData } = await api.get<Flow>(`/flows/${data.flowId}`);
    data.flow = flowData;
    return data;
  }
);

// *** METHODS FOR UPDATING STATUS OF APPILICATION ***
export const denyApplication = createAsyncThunk<
  void,
  { id: number; message: string }
>("applicationDetail/deny", async (payload, thunkApi) => {
  await api.patch(`submissions/${payload.id}/deny`, {
    message: payload.message,
  });

  await Promise.all([
    thunkApi.dispatch(getApplicationTasks(payload.id)),
    thunkApi.dispatch(getApplicationDetail(payload.id)),
    thunkApi.dispatch(getApplicationActivity(payload.id)),
  ]);
});

export const approveApplication = createAsyncThunk<
  void,
  { id: number; payload: any }
>("applicationDetail/approveApplication", async ({ id, payload }, thunkApi) => {
  await api.patch(`submissions/${id}/approve`, payload);
  await Promise.all([
    thunkApi.dispatch(getApplicationTasks(id)),
    thunkApi.dispatch(getApplicationDetail(id)),
    thunkApi.dispatch(getApplicationActivity(id)),
  ]);
});

// *** METHODS FOR REFRESHING APPILICATION ***
export const refreshApplication = createAsyncThunk<void, number | undefined>(
  "applicationDetail/refreshApplication",
  async (id, thunkApi) => {
    if (!id) {
      return;
    }
    await Promise.all([
      thunkApi.dispatch(getApplicationTasks(id as number)),
      thunkApi.dispatch(getApplicationPdfs(id as number)),
      thunkApi.dispatch(getApplicationScreenshots(id as number)),
      thunkApi.dispatch(getApplicationDetail(id as number)),
      thunkApi.dispatch(getApplicationActivity(id as number)),
      thunkApi.dispatch(getDecisionStatusLogs(id as number)),
      thunkApi.dispatch(getOnboardingApplicationResponses(id as number)),
    ]);
  }
);

// *** CHANGE ASSIGNED ADMIN ***
export const changeAssignedAdmin = createAsyncThunk<void, number>(
  "applicationDetail/changeAssignedAdmin",
  async (adminId, thunkApi) => {
    const state: RootState = thunkApi.getState() as any;
    await api.patch(`submissions/${state.applicationDetail.application?.id}`, {
      benevolenceAdminId: adminId,
    });
    await thunkApi.dispatch(
      getApplicationDetail(state.applicationDetail.application?.id as number)
    );
  }
);

// *** DECISION STATUS METHODS ***
export const getAvailableDecisionStatuses = createAsyncThunk<
  DecisionStatus | undefined,
  number
>("applicationDetail/getAvailableDecisionStatusses", async (id, _thunkApi) => {
  const { data } = await api.get<DecisionStatus | undefined>(
    `submissions/${id}/decision-statuses/available`
  );
  return data;
});

export const getDecisionStatus = createAsyncThunk<
  DecisionStatus | undefined,
  number
>("applicationDetail/getDecisionStatus", async (id, _thunkApi) => {
  const { data } = await api.get<DecisionStatus | undefined>(
    `submissions/${id}/decision-statuses`
  );
  return data;
});

export const changeDecisionStatus = createAsyncThunk<
  void,
  ChangeDecisionStatusPayload
>("applicationDetail/changeDecisionStatus", async (payload, thunkApi) => {
  const state: RootState = thunkApi.getState() as any;
  const { newDecisionStatusUuid, denialReason } = payload;

  // Perform the API patch request with the new fields
  await api.patch(
    `submissions/${state.applicationDetail.application?.id}/decision-statuses/${newDecisionStatusUuid}`,
    { denialReason }
  );

  await Promise.all([
    thunkApi.dispatch(
      getDecisionStatus(state.applicationDetail.application?.id as number)
    ),
    thunkApi.dispatch(
      getDecisionStatusLogs(state.applicationDetail.application?.id as number)
    ),
  ]);
});

// *** DECISION STATUS Log METHODS ***
export const getDecisionStatusLogs = createAsyncThunk<
  DecisionStatusLog[],
  number
>("applicationDetail/getDecisionStatusLogs", async (id, _thunkApi) => {
  const { data } = await api.get<DecisionStatusLog[] | undefined>(
    `submissions/${id}/decision-status-logs`
  );
  return data || [];
});

// *** TASK (REQUIRED DOCUMENT) METHODS ***
export const getApplicationTasks = createAsyncThunk<Task[], number>(
  "applicationDetail/getApplicationTasks",
  async (id, _thunkApi) => {
    const { data } = await api.get<TasksAPIResponse>(
      `/submissions/${id}/tasks?limit=1000`
    );
    return data.records.map((item) => {
      return {
        ...item,
        questions: item.metadata.questions,
      };
    });
  }
);

// *** PDF METHODS ***
export const getApplicationPdfs = createAsyncThunk<Pdf[], number>(
  "applicationDetail/getApplicationPdfs",
  async (id, _thunkApi) => {
    const { data } = await api.get<Pdf[]>(`/submissions/${id}/pdfs`);
    return data || [];
  }
);

// *** SCREENSHOT METHODS ***
export const getApplicationScreenshots = createAsyncThunk<Screenshot[], number>(
  "applicationDetail/getApplicationScreenshots",
  async (id, _thunkApi) => {
    const { data } = await api.get<Screenshot[]>(
      `/submissions/${id}/screenshots`
    );
    return data || [];
  }
);

export const updateTask = createAsyncThunk<
  Task,
  { questions: Questions[]; id: number }
>("applicationDetail/updateTask", async (task, thunkApi) => {
  const { applicationDetail }: RootState = thunkApi.getState() as any;
  const body: any = { questions: task.questions };
  const { data } = await api.patch<Task>(`submission-tasks/${task.id}`, body);
  await Promise.all([
    thunkApi.dispatch(
      getApplicationTasks(applicationDetail.application?.id as number)
    ),
    thunkApi.dispatch(
      getApplicationDetail(applicationDetail.application?.id as number)
    ),
    thunkApi.dispatch(
      getApplicationActivity(applicationDetail.application?.id as number)
    ),
  ]);

  return data;
});

// *** POPULATE ACTIVITY LOG FROM PULSE TABLE ***
export const getApplicationActivity = createAsyncThunk<ActivityItem[], number>(
  "applicationDetail/getApplicationActivity",
  async (id, _thunkApi) => {
    const {
      data: { records },
    } = await api.get<ApplicationActivityAPIResponse>(
      `/submissions/${id}/feed?limit=1000`
    );
    return records.map((item) => {
      return {
        ...item,
        payload: {
          ...item.payload,
          message: item.payload.message?.description,
          paymentAmount: item.payload.submission_payment?.amount,
          denialMessage: item.payload.denial_message,
          campaignInformation: item.payload.campaign_information,
          notificationType: item.payload.notification_type,
          oldDecisionStatus: item.payload.old_decision_status,
          newDecisionStatus: item.payload.new_decision_status,
          decisionRuleTitle: item.payload.decision_rule_title,
        },
      };
    });
  }
);

// *** NOTES TAB METHODS *** //
export const getApplicationNotes = createAsyncThunk<Note[], any>(
  "applicationDetail/getApplicationNotes",
  async (params, _thunkApi) => {
    const { data } = await api.get<NotesAPIResponse>(
      `/submissions/${params.id}/notes?sort=updated_at&direction=desc&limit=1000`
    );
    return data.records.map((note) => {
      return note;
    });
  }
);

export const addNote = createAsyncThunk<void, number>(
  "applicationDetail/addNote",
  async (id, thunkApi) => {
    await api.post(`/submissions/${id}/notes`, {
      body: "Add your note content here.",
    });
    await thunkApi.dispatch(getApplicationNotes({ id: id }));
  }
);

export const updateNote = createAsyncThunk<void, any>(
  "applicationDetail/updateNote",
  async (params, thunkApi) => {
    await api.patch(
      `/submissions/${params.applicationId}/notes/${params.noteId}`,
      { title: params.title, body: params.body }
    );
    await thunkApi.dispatch(getApplicationNotes({ id: params.applicationId }));
  }
);

export const deleteNote = createAsyncThunk<void, any>(
  "applicationDetail/deleteNote",
  async (params, thunkApi) => {
    await api.delete(
      `/submissions/${params.applicationId}/notes/${params.noteId}`
    );
    await thunkApi.dispatch(getApplicationNotes({ id: params.applicationId }));
  }
);
// *** MESSAGES TAB METHODS *** //
export const getApplicationMessages = createAsyncThunk<void, any>(
  "applicationDetail/getApplicationMessages",
  async (id, _thunkApi) => {
    const { data } = await api.get<any>(
      `/submissions/${id}/comments?limit=1000`
    );
    return data.records.map((message: any) => {
      return message;
    });
  }
);

// *** FRAUD GUARD+ TAB METHODS *** //
export const getFraudEvaluation = createAsyncThunk<void, any>(
  "applicationDetail/getFraudEvaluation",
  async (id, _thunkApi) => {
    const { data } = await api.get<any>(`/submissions/${id}/fraud-evaluations`);
    return data;
  }
);

// *** BUSINESS GUARD+ TAB METHODS *** //
export const getBusinessEvaluation = createAsyncThunk<
  BusinessEvaluationResponse,
  string
>("applicationDetail/getBusinessEvaluation", async (id, _thunkApi) => {
  const { data } = await api.get<BusinessEvaluationResponse>(
    `/submissions/${id}/business-evaluations`
  );
  return data;
});

export const getIPQSFraudResults = createAsyncThunk<
  FinancialApplicationFraudResult[],
  string
>("applicationDetail/getIPQSFraudResults", async (id, _thunkApi) => {
  const { data } = await api.get<FinancialApplicationFraudResult[]>(
    `/submissions/${id}/applications/fraud-results`
  );
  return data;
});

export const getIPQSApplications = createAsyncThunk<
  FinancialApplication | undefined,
  string
>("applicationDetail/getIPQSApplications", async (id, _thunkApi) => {
  try {
    const { data } = await api.get<FinancialApplication>(
      `/submissions/${id}/applications`
    );
    return data;
  } catch {
    return undefined;
  }
});

// *** FIS GKYC TAB METHODS *** //
export const getFisGkycEvaluation = createAsyncThunk<void, any>(
  "applicationDetail/getFisGkycEvaluation",
  async (id, _thunkApi) => {
    const { data } = await api.get<any>(
      `/submissions/${id}/fis-gkyc-evaluations`
    );
    return data;
  }
);

// *** WATCHLIST TAB METHODS *** //
export const getWatchlistOrders = createAsyncThunk<
  FinancialApplicationWatchlistOrder[],
  string
>("applicationDetail/getWatchlistOrders", async (id, _thunkApi) => {
  const { data } = await api.get<FinancialApplicationWatchlistOrder[]>(
    `/submissions/${id}/applications/watchlist-orders`
  );
  return data;
});

// -------------- APPLICATION STATE MANAGEMENT --------------
// When the specified API call is fulfilled, update the application state with the returned data.
const applicationDetailSlice = createSlice({
  name: "applicationDetail",
  initialState,
  reducers: {},
  extraReducers: {
    [getApplicationDetail.fulfilled.type]: (
      state,
      action: PayloadAction<ApplicationDetail>
    ) => {
      state.application = action.payload;
    },
    [getApplicationActivity.fulfilled.type]: (
      state,
      action: PayloadAction<ActivityItem[]>
    ) => {
      state.activity = action.payload;
    },
    [getAvailableDecisionStatuses.fulfilled.type]: (
      state,
      action: PayloadAction<DecisionStatus[]>
    ) => {
      state.availableDecisionStatuses = action.payload.sort(
        (a, b) => a.sortOrder - b.sortOrder
      );
    },
    [getDecisionStatus.fulfilled.type]: (
      state,
      action: PayloadAction<DecisionStatus>
    ) => {
      state.decisionStatus = action.payload;
    },
    [getDecisionStatusLogs.fulfilled.type]: (
      state,
      action: PayloadAction<DecisionStatusLog[]>
    ) => {
      state.decisionStatusLogs = action.payload;
    },
    [getApplicationTasks.fulfilled.type]: (
      state,
      action: PayloadAction<Task[]>
    ) => {
      state.tasks = action.payload;
    },
    [getApplicationPdfs.fulfilled.type]: (
      state,
      action: PayloadAction<Pdf[]>
    ) => {
      state.pdfs = action.payload;
    },
    [getApplicationScreenshots.fulfilled.type]: (
      state,
      action: PayloadAction<Screenshot[]>
    ) => {
      state.screenshots = action.payload;
    },
    [getApplicationNotes.fulfilled.type]: (
      state,
      action: PayloadAction<Note[]>
    ) => {
      state.notes = action.payload;
    },
    [getApplicationMessages.fulfilled.type]: (
      state,
      action: PayloadAction<any>
    ) => {
      state.messages = action.payload;
    },
    [getFraudEvaluation.fulfilled.type]: (
      state,
      action: PayloadAction<any>
    ) => {
      state.fraudData = action.payload;
    },
    [getBusinessEvaluation.fulfilled.type]: (
      state,
      action: PayloadAction<BusinessEvaluationResponse>
    ) => {
      state.businessGuardData = action.payload;
    },
    [getIPQSApplications.fulfilled.type]: (
      state,
      action: PayloadAction<any>
    ) => {
      state.applicationsData = action.payload;
    },
    [getIPQSFraudResults.fulfilled.type]: (
      state,
      action: PayloadAction<any>
    ) => {
      state.fraudResultsData = action.payload;
    },
    [getFisGkycEvaluation.fulfilled.type]: (
      state,
      action: PayloadAction<any>
    ) => {
      state.fisGkycEvaluations = action.payload;
    },
    [getWatchlistOrders.fulfilled.type]: (
      state,
      action: PayloadAction<FinancialApplicationWatchlistOrder[]>
    ) => {
      state.watchlistData = action.payload;
    },
    [getOnboardingApplicationResponses.fulfilled.type]: (
      state,
      action: PayloadAction<any>
    ) => {
      const { flow: flowData, ...onboardingApplicationData } = action.payload;
      state.onboardingApplicationData = onboardingApplicationData;
      state.flowData = flowData;
    },
  },
});

export const selectApplicationDetail = (state: RootState) =>
  state.applicationDetail;

export default applicationDetailSlice.reducer;
